//lose the glow
.has-error .form-control:focus,
.form-control.has-error:focus, 
.form-control:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
}

//error color
.has-error .form-control,
.form-control.has-error {
    border-color: $basecolor1;
 }

.has-error .help-block, .has-error .control-label, .has-error .radio, .has-error .checkbox, .has-error .radio-inline, .has-error .checkbox-inline, .has-error.radio label, .has-error.checkbox label, .has-error.radio-inline label, .has-error.checkbox-inline label {
    color: #a94442;
    color: $basecolor1;
}

.form-msg-danger{
    color:$basecolor1;
}

.form-msg-warning,
.form-msg-warn{
    color:$basecolor2;
}

.form-msg-success{
    color:$basecolor3;
}

.form-msg-info{
    color:$basecolor4;
}

/**select-2*/

.has-error{
    span.select2-selection{
        border-color: $basecolor1;        
    } 
}

a.has-error{
    color: $basecolor1;
}

div[class^="col-"]{
    //override fixed style width when inside bootstrap col
    span.select2-container[style] {
        width:100% !important;
    } 
}