.panel-realtime{
    
    div.chart-title{
       font-family: 'Open Sans';
       font-size: 11px;
       color: $color-text-neutral-message;
    }
    
    #realTimeChart{
        
        a{
            display: none;
            
        }
        
    }
    
}