/**prevents columns (form inputs) from sticking together when collapsing*/
@media (max-width: 768px) {

  form [class*="col-"]:not(:last-child){
      margin-bottom: 0.5em;
  }

}

/**only the topmost .container must have a padding*/
body > div.container {
    padding-top: 24px;
}
/**all containers following the first must have padding 0*/
body > div.container ~ div.container {
    padding-top: 0px;
}

.container{
    
    &.background{
        position: fixed;
        top:0px;
        bottom:0px;
        left:0px;
        right:0px;
        background-color: #ff9900;
        z-index: -100;
    }
    
    /**grows container until full body height */    
    &.fullheight {
        /*height: 100%;*/
        min-height: 100%;
    }
    
    &.fill {
        background-color:white;
        
        &.grey{
            background-color:$background-grey;
        }
        
        &.whitewash {
             background-color:$background-whitewash;
        }
        
        &.darken {
             background-color:$background-darken;
        }
        
        &.none {
            background-color: transparent;
        }
    }
}

/**columns of equal height (flex)*/
.row-eq-height {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display:         flex;
}

 @media (max-width: 991px){

    .row-eq-height {
         -webkit-flex-direction: column;
        flex-direction: column;
    }
        
}

/**prevent bootstraps widest layout (1170)*/
@media (min-width: 1200px){
    .container {
        width: 970px;
    }
}