.btn {
    padding: 14px 24px;
    border: 0 none;
    /*font-weight: 700;*/
    letter-spacing: 1px;
    text-transform: lowercase;
    
    &:focus, &:active:focus, &.active:focus {
        outline: 0 none;
    }
    
    &.btn-m {
        padding: 7px 12px;
        font-size: 12px;
    }
    &.btn-sm {
        padding: 5px 10px;
        font-size: 12px;
    }
    &.btn-xs {
        padding: 1px 5px;
        font-size: 12px;
    }
    
    &.disabled:hover,
    &.disabled {
        background: #cecece !important;
        color: #aaaaaa;
    }
}
 
.btn-circle {
    padding: 6px 12px;
    border-radius: 50%;
}

.btn-vcenter {
  position: absolute;
  top: 50%;
  @include transform(translateY(-50%));
}

/*.btn:focus, .btn:active:focus, .btn.active:focus {
    outline: 0 none;
}*/

/**primary*/
.btn-primary {
    background: #0099cc;
    color: #ffffff;
    
    &:hover, &:focus, &:active, &.active{
        background: #33a6cc;
    }
    &:active, &.active {
        background: #007299;
        box-shadow: none;
    }
}
 
.open > .dropdown-toggle.btn-primary {
    background: #33a6cc;
}

/**GBA COLORED BUTTONS**/

.btn-primary.red {
    background: $basecolor1;
    
    &:hover, &:focus, &:active, &.active{
        background: $basecolor1-hover;
    }
    &:active, &.active {
        background: $basecolor1-active;
        box-shadow: none;
    }
}
 
.open > .dropdown-toggle.btn-primary.red {
    background: $basecolor1-hover;
}

.btn-primary.yellow {
    background: $basecolor2;
    
    &:hover, &:focus, &:active, &.active{
        background: $basecolor2-hover;
    }
    &:active, &.active {
        background: $basecolor2-active;
        box-shadow: none;
    }
}
 
.open > .dropdown-toggle.btn-primary.yellow {
    background: $basecolor2-hover;
}

.btn-primary.green {
    background: $basecolor3;
    
    &:hover, &:focus, &:active, &.active{
        background: $basecolor3-hover;
    }
    &:active, &.active {
        background: $basecolor3-active;
        box-shadow: none;
    }
}
 
.open > .dropdown-toggle.btn-primary.green {
    background: $basecolor3-hover;
}

.btn-primary.blue {
    background: $basecolor4;
    
    &:hover, &:focus, &:active, &.active{
        background: $basecolor4-hover;
    }
    &:active, &.active {
        background: $basecolor4-active;
        box-shadow: none;
    }
}
 
.open > .dropdown-toggle.btn-primary.blue {
    background: $basecolor4-hover;
}

.btn-primary.grey {
    background: #d8d8d8;
    color: #818181;
    
    &:hover, &:focus, &:active, &.active{
        background: #c9c9c9;
    }
    &:active, &.active {
        background: #eaeaea;
        box-shadow: none;
    }
}
 
.open > .dropdown-toggle.btn-primary.blue {
    background: #c9c9c9;
}

.btn-primary.black {
    background: #000;
    
    &:hover, &:focus, &:active, &.active{
        background: #FFF;
        color: #000;
    }
    &:active, &.active {
        background: #C3C3C3;
        color: #000;
        box-shadow: none;
    }
}
 
.open > .dropdown-toggle.btn-primary.black {
        background: #FFF;
        color: #000;
}

.navbar-default .navbar-container .btn-nav-help {
    position: absolute;
    right: 20px;
    top: 30px;
    height: 35px;
    width: 35px;
}


.navbar-default .navbar-container .btn-nav-language {
    position:absolute;
    top:50%;
    margin-top:-17px;
    margin-right:inherit;
    right:65px;
    width:35px;
    height:35px;
    padding:0;
}
 
.navbar-default .navbar-container .btn-nav-language button {
    width:35px;
    height:35px;
    padding:0;
}

.navbar-default .navbar-container .btn-nav-language img {
    vertical-align:top;
    margin-top:3px;
}

.navbar-default .dropdown-menu {
    margin-left: 2px;
    padding-left: 5px;
    min-width: 33px;
}

.navbar-default .clientLanguageDropdown {
    position: absolute;
    display: table-cell;
    margin-left: 100px;
    margin-top: -50px;
    width: 30px;
    background-color: #ff9c8b;
    border-radius: 3px;
    padding: 3px;
    display:none;
    cursor:hand;
}

 #btnHelp{
    
   /* top:0px;
    bottom:0px;*/
   /* width:*/
}

//chapter button

.btn-chapter {
    padding: 0px 6px;
    border: 0 none;
    background-color: #FFF;
    color:#000;
/*    letter-spacing: 1px;*/
    text-transform: none;
    
    &:hover {
        background-color: $background-grey;
    }
    &:active {
        @include box-shadow(none);
    }
    &.selected {
        font-weight: 700;
    }
    &.selected:hover {
        background-color: #FFF;
        cursor: default;

    }
    &:after {
        content:" >";
        font-size: 1.5em;
    }
    &:last-child::after {
        content:"";
    }
}

div.favorite{
    position: fixed;
    color: #FFF;
    font-size: 20px;
    right: 40px;
    top: 3px;
    cursor: pointer;
    float: right;
}

button:focus{
    outline: none;
}

   .panel-tilecontent{
    
        button.close,
        a.close {
            position:relative;
            top:0px;
            right:0px;
            z-index: 10;
            width:24px;
            height:24px;
            opacity: 1;
            text-shadow: 0 0px 0 #FFF;
            text-align: center;
            font-weight: normal;

           color: #FFF;
           background: #bababa;
           &:hover{
             color: #bababa;
             background: #FFF; 
           }
        }

        button.close {
            line-height: 0.5;
        }
}