.legend-box{
    float:right;
    max-width: 240px;
}

.legend-container{
    position: relative;
    font-size: 0.8em;
    border: 1px solid $background-grey;
    padding:4px 20px;


    
    & div {
        clear:both;
        display:block;
        padding-left:20px;
    }

    &.realtime div{
        clear: none;
        display: inherit;
    }
    
    
    
    & div div{
        float:left;
        padding:0px;
        width: 10px;
        height: 10px;
        margin: 5px 5px 10px -20px;
        background: $background-grey;
    }

    .youranswer div{
        background: $basecolor3;
    }
    
    .yourbenchmark div{
        background-color: $basecolor2;
    }
    
    .benchmark div{
        background: $basecolor1;
    }
    
    .myanswer-realtime div::before {
        
          content: ">";
          color: $basecolor3;
          font-weight: bold;
          font-size: 1.2em;
          position: relative;
          top: -7px;
          height: 8px;//10
          left: -12px;
    }
    
    .myanswer-realtime {
       font-weight: bold;
        /*margin-left: -2px;*/
        padding: 0px 2px 0px 22px;
   /*     margin-bottom: 14px;
        margin-top: 14px;*/
       /* border: 1px solid $basecolor3;*/
    }
    
}