div.goals-label,
div.goals-container{
    
    /*
    display:inline-block;
    width: 100%;
    */
}

div.goals-label{
    padding-top: 11px;
    padding-bottom: 11px;
    font-weight: 700;
}

div.goals-container{
    padding: 11px;
    border: 1px solid lightgrey;
    border-radius: 2px;
    
    //truncate
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-wrap: normal;

    span {
        //IE truncate text fix
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100%;
    }
    
    .btn{
        margin: -10px;
    }
}

/*       overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        word-wrap: normal;
        
        span {
            //IE truncate text fix
            display: inline-block;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 100%;
        }*/

div.progress-circle{
    
    .widget-label{
        width: 100%;
        text-align: right;
    }
    
    @media (max-width: 768px){
        .widget-label{
            margin-top: 1em;
        }
    }
    @media (max-width: 991px){
        .widget-label{
            text-align: center;
        }
    }
    
    /*min-height:150px;*/
    
    #svg-progress-circle circle {
      /*stroke-dashoffset: 0;*/
      @include transition(stroke-dashoffset 1s linear);
      stroke: $basecolor3circlestroke;
      stroke-width: 2em;
    }
    
    #svg-progress-circle #bar {
      stroke: $basecolor3;
    }
    
    #svg-progress-circle-container {
      display: block;
      width: 100px;
      height: 100px;
      margin: 0 auto;
      border-radius: 100%;
      position: relative;
    }
    
    #svg-progress-circle-container:after {
      position: absolute;
      display: block;
      width: 100px;
      height: 100px;
      left: 50%;
      top: 50%;
      content: attr(data-pct)"%";
      margin-top: -50px;
      margin-left: -50px;
      border-radius: 100%;
      line-height: 100px;
      font-size: 1.5em;
      text-align: center;
    }
    
}

div.progress-bar-container {
    
        width: 100%;

        #svg-progress-bar {
            #track {
                stroke-width: 4;
                stroke:$basecolor2;
            }
        }

        #svg-progress-ball{
            
            overflow: visible;
                        
            line {
                stroke-width: 4;
                stroke:$basecolor1;
            }

            circle {
                fill: $basecolor1;
            }

        }
}


.media-trigger-container{
    
        position: absolute;
        width:100%;
        height:100%;
         
        div.media-thumb-container {

            position: relative;
            width:100%;
            height:100%;
            white-space: normal;
            background-size: cover;
            background-repeat: no-repeat;    
            background-position: center center;    

                .media-description {
                    position: absolute;
                    bottom: 0px;
                    left: 0px;
                    right: 0px;
                    background: $description-background;
                    color: black;
                    height: 50px;
                    padding-left: 15px;
                    padding-right: 15px;
                    font-size: 14px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    word-wrap: normal;

                    span {/**IE truncate text fix*/
                        display: inline-block;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        max-width: 100%;
                    }

                    h5{
                        font-weight: 700;
                    }
                    
                    &+ .icon-play{
                        margin-top: -50px;
                    }
                    
                 }
    }}