.glyphicon {
    margin-top: 5px;
    margin-bottom: 10px;
    font-size: 24px;
}

.fa {
    
    &.fa-1 {
        font-size: 1em;
    }   
    &.fa-1_5 {
        font-size: 1.5em;
    }    
    &.fa-2 {
        font-size: 2em;
    }
    &.fa-3 {
        font-size: 3em;
    }
    &.fa-4 {
        font-size: 24m;
    }
    &.fa-5 {
        font-size: 5em;
    }
}

/** display and center the play icon*/
div.icon-play {
    width: 64px;
    height: 64px;
    background-image: url('/client/assets/images/icon-play.png');
    background-repeat: no-repeat;
    background-position: center;
    position:absolute;
    top: 50%;
    left: 50%;
    margin: -32px 0 0 -32px;
}

/** display and center the completed icon*/
div.icon-completed {
    width: 84px;
    height: 84px;
    background-image: url('/client/assets/images/icon-completed.png');
    background-repeat: no-repeat;
    
    position:absolute;
    top: 50%;
    left: 50%;
    margin: -77px 0 0 -42px;
}

/** display and center the completed icon*/
div.icon-completed-video {
    width: 114px;
    height: 84px;
    background-image: url('/client/assets/images/icon_completed_video.png');
    background-repeat: no-repeat;
    
    position:absolute;
    top: 50%;
    left: 50%;
    margin: -77px 0 0 -60px;
}