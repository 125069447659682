$sliderselection: #BABABA;
$slidertick: #d0d0d0;
$slidertrack: #d0d0d0;
$sliderhandle1: $basecolor3;
$sliderhandle2: $basecolor1;

.slider.slider-horizontal {
    width: 100%;
   /* width: 250px;*/
    height: 20px;
    margin-bottom: 1em;
    
    .slider-tick-label-container {
        margin-top: 25px;
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
         -khtml-user-select: none; /* Konqueror HTML */
           -moz-user-select: none; /* Firefox */
            -ms-user-select: none; /* Internet Explorer/Edge */
                user-select: none; /* Non-prefixed version, currently
                                      supported by Chrome and Opera */        
        
    }
}

.slider-tick-container {
    pointer-events: none;
}

/**handle 40x40 pixels*/
.slider-handle{
    top:-10px;
    margin-left:-20px!important;
    width: 40px;
    height: 40px;
    opacity:1;
    background-color: $sliderhandle1;
    background-image: none;
    filter: none;
    box-shadow: none;
}

.slider.slider-disabled .max-slider-handle{
    /*opacity:0.5;*/
    background-color: $sliderhandle2;
}

.slider.slider-disabled.equal .max-slider-handle{
    border: 3px solid white;
}

.slider.slider-disabled .max-slider-handle{
    top:-6px;
    margin-left:-16px!important;
    width: 32px;
    height: 32px;
    background-color: $sliderhandle2;
    background-image: none;
    filter: none;
    box-shadow: none;
}

.slider-track {
    background: $slidertrack;
    background-image: none;
    filter: none;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.slider-selection {
    background: $sliderselection;
    background-image: none;
    filter: none;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.slider-selection.tick-slider-selection {
  background: $slidertick;
  background-image: none;
  filter: none;
  opacity: 1;
}

.slider-tick {
  position: absolute;
  width: 20px;
  height: 20px;
  background: $slidertick;
  background-image: none;
  filter: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  opacity: 1;
}

.slider-tick.in-selection {
  background: $slidertick;
  background-image: none;
  filter: none;
  opacity: 1;
}

.slider.slider-disabled .slider-handle {
  background-image: none;
  filter: none;
}

.slider.slider-disabled .slider-track {
  background-image: none;
  filter: none;
  cursor: not-allowed;
}

.slider.slider-horizontal .slider-tick-label-container div.slider-tick-label{
    /*word-wrap: break-word;*/
    white-space: normal !important;
    
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
    
}

.many-answer-options{
    
    font-size: 0.8em;    
    
}

@media (max-width: 768px){
        
    .slider.slider-horizontal .slider-tick-label-container div.slider-tick-label:nth-child(odd) {
        word-wrap: break-word;
      /*font-size: 0.9em;
        height:50px;*/
    }

    .slider.slider-horizontal .slider-tick-label-container div.slider-tick-label:nth-child(even) {
        word-wrap: break-word;
        font-size: 0.9em;
    /*    height:50px;
        padding-top:24px;
        vertical-align: bottom;*/
    }
    
}