.drag-box{
  
//clone approach
    
    .target{
      position:absolute;
      width:40%;
      right:1%;
      background-color:white;
      border:1px solid silver;
      border-radius: 4px;
      margin-bottom:4px;
      min-height:50px;
      line-height:30px;
    }
    
    #scroll-box {
      border: 1px solid #ccc;
      border-radius: 4px;
      padding: 4px;
      /*white-space: nowrap;*/
      overflow-y: scroll;
      overflow-x:hidden;
      height:350px;
      width:40%;        
    }

    #drag-item-container {
      //display: block;
    }

    .drag-item-wrapper {
      width: 100%;
      height: 50px;
      margin-bottom: 0.2em;
      align-items: center;
      justify-content: center;
      position: relative;
        
        &.collapsed{
            margin-bottom: 0;
        }
    }

    .drag-item {
        
      background-color: $basecolor3;
      border-radius: 4px;
      padding:2px 4px;
      color: #fafafa;
      align-items: center;
      justify-content: center;
      font-size: 0.9em;
      position: relative;
      width: 100%;
      height: 100%;
      cursor: move;
      overflow: hidden;
        
        span{
            pointer-events: none;
        }
        
        &.clone {
          position: absolute;
          opacity: 0;
          visibility: hidden;
          width: 40%;
          height: 50px;        
          //background: $basecolor2;
        }
        
       /* &.dropped {
            background: cornflowerblue;
        }*/
    }
    
    .text-overflow{
        font-size: 0.7em;
    }
}

.drag-results{
    
    .answer-option{
            
      background-color: $basecolor3;
      border-radius: 4px;
      padding:2px 4px;
      margin-bottom: 4px;
      color: #fafafa;
      align-items: center;
      justify-content: center;
      font-size: 0.9em;
      /*position: relative;*/
      width: 100%;
      min-height: 50px;
      overflow: hidden;
        
        &.other{
            background: $basecolor1;
        }
    }
    
}