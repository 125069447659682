
.bubble 
{
    position: absolute;
    right: 10px;
    width: 270px;
    padding:5px 10px 5px 10px;
    margin:-105px 0;
    background: #FFFFFF;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    border-radius: 12px;
    color:#333;
    box-shadow: 0px 0px 10px #888888;
}

.bubble span.bubbleCorrect {
    color:#92c82a;    
}

.bubble span.bubbleInCorrect {
    color:#525252;    
}

.bubble span.bubbleInCorrect.bubbleInCorrectWarning {
    color:#F44336;    
}

.bubble span.bubbleIcon {
    background-image : url('/assets/images/passwordIcon.png');
    background-repeat : no-repeat;
    background-size: 100% 100%;  
    height: 12px;
    width: 13px;
    display: inline-block;
    margin-right: 4px;
}

.bubble span.bubbleIcon.bubbleIconWarning {
    background-image : url('/assets/images/passwordWarning.png');
}

.bubble span.bubbleIconCorrect {
    background-image : url('/assets/images/passwordCorrect.png');
    background-repeat : no-repeat;
    background-size: 100% 100%;  
    height: 12px;
    width: 13px;
    display: inline-block;
    margin-right: 4px;
}


.bubble:after 
{
    content: '';
    position: absolute;
    border-style: solid;
    border-width: 14px 15px 0;
    border-color: #FFFFFF transparent;
    display: block;
    width: 0;
    z-index: 1;
    bottom: -14px;
    left: 224px;
}
