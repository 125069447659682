.col-right .filter-container{
    
    //padding-left:20px;
    
    @media (max-width: 768px){
        padding-left: 0px;
    }

}

$filter-border-color: #c3c3c3;

.filter-container{
    
    h1:first-of-type,
    h2:first-of-type,
    h3:first-of-type,
    h4:first-of-type,
    h5:first-of-type{
        margin-top: 0;
    }
    
    h1,h2,h3,h4,h5{
        color:$basecolor1;
    }

    label.checkbox-label{
        cursor:pointer;
        display: block;
        margin-left: 30px;
        font-weight: normal;
        margin-bottom: 10px;
    }
    input[type="checkbox"] {
        display:none;
    }
    input[type="checkbox"] + div {
        /*display:inline-block;*/
        float:left;
        width:20px;
        height:20px;
        margin:-2px 10px 0 -30px;
        padding: 2px;
        vertical-align:middle;
        border: 1px solid $filter-border-color;
        border-radius: 3px;
        background:#FFF;
        cursor:pointer;
    }
    
    input[type="checkbox"]:checked + div{
        background: inherit;
        border-color: $filter-border-color;
    }
    
    input[type="checkbox"] + div div {
        width:14px;
        height:14px;
        background:none;
    }
    input[type="checkbox"]:checked + div div {
        background:#000;
    } 
    
    
}