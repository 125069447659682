// @import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

$font-stack: 'DINOT', 'PT Sans', 'Open Sans', sans-serif;
$primary-color: #333;
$color-offwhite: #ffffff;
/*
#f04023 
#fda61c 
#30c4b1 
#0c89a2 
*/
$basecolor1:#f04023;
$basecolor2:#fda61c;
$basecolor3:#30c4b1;
$basecolor4:#0c89a2;

$basecolor1-hover:#ff5438;
$basecolor2-hover:#ffb642;
$basecolor3-hover:#34d4bf;
$basecolor4-hover:#0ea3c1;

$basecolor1-active:#d1381f;
$basecolor2-active:#e69719;
$basecolor3-active:#29a999;
$basecolor4-active:#0b798f;

$basecolor3circlestroke:#c9efea;

$background1:darkolivegreen;
$background-body: $background1;

$background-grey:#e8e8e8;
$background-whitewash: rgba(255,255,255,0.1);
$background-darken: rgba(0,0,0,0.4);

$description-background: rgba(255, 255, 255, 0.85);

/**navbar*/
$background-nav-1: rgba(255, 255, 255, 1);
$background-nav-hover: rgba(231, 231, 231, 1);
$background-trans: rgba(255, 255, 255, 0);

$color-nav-1:rgba(119, 119, 119, 1);
$color-nav-hover:rgba(51, 51, 51, 1);
$color-nav-hover-active:rgba(85, 85, 85, 1);

$background-nav-sidebar: $basecolor1;
$background-nav-hover-sidebar: rgb(209, 56, 31);
$background-nav-hover-active-sidebar: rgb(209, 56, 31);

$color-nav-1-sidebar:rgb(255, 255, 255);
$color-nav-hover-sidebar:rgb(212, 212, 212);
$color-nav-hover-active-sidebar:rgb(255, 255, 255);

$color-text-neutral-message: #6b6b6b;

@mixin transform($transform) {
  -webkit-transform: $transform;
     -moz-transform: $transform;
      -ms-transform: $transform;
          transform: $transform;
}

@mixin box-shadow($box-shadow) {
    -moz-box-shadow: $box-shadow;
    -webkit-box-shadow: $box-shadow;
    box-shadow: $box-shadow;
}

@mixin transition($transition) {
    -moz-transition: $transition;
    -webkit-transition: $transition;
    transition: $transition;
}
//ex. @include transition(stroke-dashoffset 1s linear);

//start css declaration here

@import 'fonts';

html,body{
    height: 100%;
}

body {
  padding-top: 120px;
  padding-top: 96px;
  background-color:$background-body;    
  font-family: $font-stack;
  /*font-size: 14px;*/
  color: $primary-color;
    
    @media (max-width: 768px){
        padding-top: 60px;
    }
}

.green-text{
    color: $basecolor3;
}

.red-text{
    color: $basecolor1;
}

.container{
   // background-color: rgba(255,255,255,0.1);
   
       
    .chapterCompleted {
       margin:10px;
       padding:10px;
       text-align:center;
       background-color:white; 
       color:$basecolor3;
    }
} 

@import 'bootstrap';
@import 'bootstrap-slider';
@import 'tilegrid';
@import 'tiles';
@import 'forms';
@import 'icons';
@import 'navbars';
@import 'ibox';
@import 'panels';
@import 'drag';
@import 'modals';
@import 'buttons';
@import 'widgets';
@import 'filter';
@import 'realtime';
@import 'legend';
@import 'utils';
@import 'lity';
@import 'bubble';
