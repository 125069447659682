
/**panels*/
.panel{

    border-radius:0px;
    
    .panel-heading{
         background-color: inherit; 
         border: none; 
    }
    
    .col-right{
        border-left: 1px solid #E1E1E1;
     }
    
    @media (max-width: 768px){

        .col-right{
           /* margin-top: 0.5em;*/
            /*border-top: 1px solid #E1E1E1;*/
            border-left: none;
        }
        
    }
    
}

/**gba panel*/
.gba-panel{
    min-height: 480px;
    margin-bottom: 0px;
    border:none;    
}

.gba-panel::after{

    content: "";
    position: absolute;
    bottom: -49px;
    right: 15px;
    /*<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" preserveAspectRatio="none" width="100%" height="100%"  viewBox="0 0 100 100"><path stroke-width="0" stroke-miterlimit="3" stroke="#FFFFFF" fill="#FFFFFF" d="M0,0 L100,0 L0,100 L0,0 Z"></path></svg>*/
    background: url('data:image/svg+xml;charset=utf-8,%3Csvg%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20xml%3Aspace%3D%22preserve%22%20preserveAspectRatio%3D%22none%22%20width%3D%22100%25%22%20height%3D%22100%25%22%20%20viewBox%3D%220%200%20100%20100%22%3E%3Cpath%20stroke-width%3D%220%22%20stroke-miterlimit%3D%223%22%20stroke%3D%22%23FFFFFF%22%20fill%3D%22%23FFFFFF%22%20d%3D%22M0%2C0%20L100%2C0%20L100%2C2%20L0%2C100%20L0%2C0%20Z%22%3E%3C%2Fpath%3E%3C%2Fsvg%3E');
    width: 230px;
    height: 50px;
    background-repeat: no-repeat;
    background-size: 100% 100%;//FF needs this
    z-index: 1000;
}

/**intro*/

.intro-panel{
            
    .description-container{
        position: absolute;
        top:0;
        left:50%;
        right:15px;//for some reason right has a 15 pixel offset, not sure what causes it.
        bottom:0;
        padding:15px;
        overflow-y: auto;
        overflow-x: hidden;
        background-color: $basecolor4;
        color: white;

        h1,h2,h3,h4,h5{
            color: white;
        }
        
        img{
            //margin-left:-15px;
            display: block;
            margin: 0 auto;
        }

    }
    
    .col-right{
         h1,h2,h3,h4,h5{
            color: $basecolor1;
        }
     }
    
    .embed-responsive{
       margin-top:-15px;
       margin-left:-15px;
       margin-right:-15px;
    }

    @media (max-width: 768px){
        
        .embed-responsive{
           margin-top:0px;
        }
        
       .description-container{
            position:fixed;
            top:50%;
            left:0;
            bottom: 0;
            right: 0;
            z-index:2000;
        }
        
    }
    
    .row.cast{
        h1,h2,h3,h4,h5{
            color: $primary-color;
        }
    }
    
    .row.pros{
        h1,h2,h3,h4,h5{
            color: $basecolor3;
        }
    }
    
}

/**vouchercode-panel*/

.vouchercode-panel{
    
}

/**tile content*/
.panel-tilecontent{
    
    padding:20px;
    
    @media (max-width: 768px){
       padding:0px; 
    }
    
    .panel-body,
    .panel-heading{
        padding-top: 20px;
        font-size: 1.1em;
        
        div.salutation{
            color: $basecolor3;
            margin-bottom: 0.5em;
            font-size:1.17em;
        }
        
        div.question{
            font-size:1.17em;
            font-weight: 700;
        }
    }
    
    .answers-row,
    .slider-row{
        padding-top: 50px;
        padding-bottom: 50px;
    }
    
    label.checkbox-label,
    label.radio-label{
        cursor:pointer;
        display: block;
        margin-left: 30px;
        margin-bottom: 20px;
        font-weight: normal;
        
        &.disabled{
            cursor:default;
        }
    }
    
    input[type="checkbox"], 
    input[type="radio"] {
        display:none;
    }
    
    input[type="checkbox"] + div,
    input[type="radio"] + div {
        float: left;
        width:30px;
        height:30px;
        border-radius: 50%;
        margin:-6px 10px 0px -30px;
        padding: 1px;
        vertical-align:middle;
        border: 3px solid #c4c4c4;
        background:#5d5d5d;
        background:#fff;
        cursor:pointer;
    }
    
    input[type="checkbox"]:disabled + div,
    input[type="radio"]:disabled + div {
        cursor:default;
    }
    
    input[type="checkbox"].selected + div,
    input[type="radio"].selected + div {
        background:$basecolor1;
        border-color:$basecolor1;
    }
    
    input[type="checkbox"]:checked + div,
    input[type="radio"]:checked + div {
        background:$basecolor3;
        border-color:$basecolor3;
    }
    
    input[type="checkbox"].selected:checked + div,
    input[type="radio"].selected:checked + div {
        background:$basecolor3;
        border-color:$basecolor3;
    }
    
    input[type="checkbox"].selected:checked + div div,
    input[type="radio"].selected:checked + div div {
        width:22px;
        height:22px;
        background:$basecolor1;
        border-radius: 50%;
        border:3px solid white;
    }
    
    input[type="checkbox"] + div,
    input[type="checkbox"].selected:checked + div div {
        border-radius:3px;
    }
    
    .empty-message {
        color: $color-text-neutral-message;
        text-align: center;
        font-size: 0.9em;
    }
    
    
    .answerPercentage {
       color:$basecolor4;
       font-size:0.8em;
    }
    
    .answerExplain {
       font-size:0.8em;
    }
    
}

.panel-workdoc {
    
    #btnAdd{
/*        padding: 0px;
        margin-top: 2px;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background: #fff;
        border: 1px solid $basecolor3;
        color: $basecolor3;
        
        &:hover{
            background: $basecolor3;
            color: #fff;
        }*/
        
    }
    
    .answer-container{
        min-height: 80px;
        border: 1px solid lighten( $basecolor3, 30% );
        padding: 2%;
    }
    
       
    li {
            background: $background-goal;
            border: 1px solid $background-goal;
            padding: 6px;
            font-weight: 400;
            min-height: 24px;
            margin-bottom: 0.5em;
            
            button.close,
            a.close{
               margin-top: -2px ;
            }
            
        }
    
}