.grid-item-content--height2,
.grid-item-content {
  width: 100%;
  height: 170px;
  background: #e3e1e2;
  background-size: cover;
  background-repeat: no-repeat;    
  background-position: center center;    

    @media (max-width: 768px) {

      height:350px;

    }
    
    .tile-description {
        position: absolute;
        bottom: 0px;
        left: 5px;
        right: 5px;
        background: $description-background;
        color: black;
        height: 70px;
        padding-left: 15px;
        padding-right: 15px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        word-wrap: normal;
        
        span {/**IE truncate text fix*/
            display: inline-block;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 100%;
        }
        
        h5{
            font-weight: 700;
        }
    }
    
    .img-icon{
        position: absolute;
        width: 30px;
        height: 30px;
        right: 9px;
        top: 4px;
    }
    
}

.grid-item-content--height2 { 
    background: #b5b5b5;
    background-size: cover; 
    background-repeat: no-repeat;
    background-position: center center;
    height: 350px; 
}

.grid-item {
  margin-bottom: 10px;
    
    .tile-disabled{
        display: none;
    }
    
    &.disabled .tile-disabled{
        display: block;
        position: absolute;
        top:0px;
        bottom: 0px;
        left: 5px;
        right: 5px;
        background: rgba(255, 255, 255, 0.5);
        
    }
    
    .currentTile {
       color:white;
       background-color: $basecolor3;
    }

}

/**adjust gutter space specifically for tile grid*/
.tile-container{
    
    .col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
        padding-left: 5px;
        padding-right: 5px;
    }
    
}

