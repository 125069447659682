.testborder{
    
    border:1px dashed green;
    
    &.red{
        border-color: red;
    }
    
}

.grayscale-image{//this will not work in IE10 and 11
    filter: grayscale(100%);
}

.hidden {
    display: none;
}

.hidden-render {
    visibility: hidden;
}

.vcenter {
    display: inline-block;
    vertical-align: middle;
    float: none;
}

@media only screen and (min-width : 768px) {
    .is-table-row {
        display: table;
    }
    .is-table-row [class*="col-"] {
        float: none;
        display: table-cell;
        vertical-align: top;
    }
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

/*margin settings (usage: to set a top- or bottommargin on an element with class '.row'.. prefer bottom margin to prevent margin collapsing*/
.margin-top-xxs { margin-top: 0.5em; }
.margin-top-xs { margin-top: 1.0em; }
.margin-top-s { margin-top: 1.5em; }
.margin-top-m { margin-top: 2.0em; }
.margin-top-l { margin-top: 2.5em; }
.margin-top-xl { margin-top: 3.0em; }
.margin-top-xxl { margin-top: 3.5em; }

.margin-bottom-xxs { margin-bottom: 0.5em; }
.margin-bottom-xs { margin-bottom: 1.0em; }
.margin-bottom-s { margin-bottom: 1.5em; }
.margin-bottom-m { margin-bottom: 2.0em; }
.margin-bottom-l { margin-bottom: 2.5em; }
.margin-bottom-xl { margin-bottom: 3.0em; }
.margin-bottom-xxl { margin-bottom: 3.5em; }

/*margin settings (usage: to set a top- or bottommargin on an element with class '.row'.. prefer bottom margin to prevent margin collapsing*/
.margin-left-xxs { margin-left: 0.5em; }
.margin-left-xs { margin-left: 1.0em; }
.margin-left-s { margin-left: 1.5em; }
.margin-left-m { margin-left: 2.0em; }
.margin-left-l { margin-left: 2.5em; }
.margin-left-xl { margin-left: 3.0em; }
.margin-left-xxl { margin-left: 3.5em; }

.margin-right-xxs { margin-right: 0.5em; }
.margin-right-xs { margin-right: 1.0em; }
.margin-right-s { margin-right: 1.5em; }
.margin-right-m { margin-right: 2.0em; }
.margin-right-l { margin-right: 2.5em; }
.margin-right-xl { margin-right: 3.0em; }
.margin-right-xxl { margin-right: 3.5em; }

