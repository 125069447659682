/**font: deepest possible compatability**/
//@font-face {
//  font-family: 'MyWebFont';
//  src: url('webfont.eot'); /* IE9 Compat Modes */
//  src: url('webfont.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
//       url('webfont.woff2') format('woff2'), /* Super Modern Browsers */
//       url('webfont.woff') format('woff'), /* Pretty Modern Browsers */
//       url('webfont.ttf')  format('truetype'), /* Safari, Android, iOS */
//       url('webfont.svg#svgFontName') format('svg'); /* Legacy iOS */
//}


/*most likely enough**/
//@font-face {
//  font-family: 'MyWebFont';
//  src: url('myfont.woff2') format('woff2'),
//       url('myfont.woff') format('woff'),
//       url('myfont.ttf') format('truetype');
//       url('myfont.otf') format('opentype');
//}*/

/*most likely enough**/
@font-face {
  font-family: 'DINOT';
  src: url('../fonts/DINOT-Light.otf') format('opentype');
}/*most likely enough**/
@font-face {
  font-family: 'DINOT';
  src: url('../fonts/DINOT-Medium.otf') format('opentype');
}/*most likely enough**/
@font-face {
  font-family: 'DINOT';
  src: url('../fonts/DINOT-Regular.otf') format('opentype');
}