
.navbar-container{//important to get the positioning of the helpbutton right
    position: relative;
}


.container .navbar-colors.row {
    
    height: 6px;
    .red,.yellow,.green,.blue {
        height: 100%;
    }
    .red {
        background-color: $basecolor1;
    }
    .yellow {
        background-color: $basecolor2;
    }
    .green {
        background-color: $basecolor3;
    }
    .blue {
        background-color: $basecolor4;
    }
    
    &.fullwidth {
        position: absolute;
        right:0px;
        left:0px;
        bottom:0px;
        width:100%;
        margin: 0px;
    }
}

@media (min-width: 0){
    .navbar-toggle {
        display: block;
    }
}

.navbar-nav{
    li a {
        line-height: 96px;
        height: 96px;
        padding-top: 0;
    }
    
}

.navbar-brand,
.navbar-nav li a {
    line-height: 96px;
    height: 96px;
    padding-top: 0;
}

//navbar coloring
.navbar-default {
    font-size: 14px;
    background-color: transparent;
    /*border-bottom-width: 1px;*/
    border-bottom: none;
    
    .ibox-title{
        font-size:2em;
        @media (max-width: 768px){
            font-size:1.5em;
        }
    }
    
    .navbar-brand {
        color: $color-nav-1;
        
        @media (max-width: 768px){
            height: 60px;
            
            img {
                height:64px;
            }
        }
    }
    
    .navbar-nav>li>a {
        color: $color-nav-1;
        background-color: $background-nav-1;
    }
    
    .navbar-nav>li>a:hover,
    .navbar-nav>li>a:focus {
        color:$color-nav-hover;
        background-color: $background-trans;
    }
    
    .navbar-nav>.active>a,
    .navbar-nav>.active>a:hover,
    .navbar-nav>.active>a:focus {
        color: $color-nav-hover-active;
        background-color: $background-nav-hover;
    }    

     .navbar-nav>.active>a,
     .navbar-nav>.active>a:hover,
     .navbar-nav>.active>a:focus {
        color: $color-nav-hover-active;
        background-color: $background-nav-hover;
    }
    .navbar-toggle {
        border-color: #ddd;
        border:none;
         .icon-bar {
            background-color: #000;
        }
        @media (max-width: 768px){
            margin-right: 0px;
        }
    }
     .navbar-toggle:hover,
     .navbar-toggle:focus {
        background-color: #ddd;
         .icon-bar {
            background-color: #ffffff;
        }
    }

    .container{
        background-color: $background-nav-1;
    }
    
    /**add gap between elements*/
    .navbar-button-container {
        
       margin-top: 1.5em;
       margin-bottom: 1.5em;
       line-height: 44px;
        
        > *:not(:last-child){
            margin-right: 3em;
        }
        
        .ibox-title{
            margin-right: 0.5em;
        }
        
        @media (max-width: 768px){

            margin-top: 0px;
            margin-bottom: 0px;
            
            > *:not(:last-child){
                margin-right: 0.5em;
            }
        }
    }
    
    @media (max-width: 768px){
            
        
    }

}

/**sidebar*/
.gba-sidebar {
      min-width: 250px;
      width: 250px;
      min-height: 100%;
    
}
.gba-sidebar.navbar-default {
    
    padding:15px 20px;    
    font-size: 14px;
    background-color: $background-nav-sidebar;
    border-bottom-width: 1px;
    border-left: none;
    color: white;

    p {
        white-space: normal;
    }
    //navbar sidebar coloring
    .navbar-brand {
        color: $color-offwhite;
    }

    .navmenu-nav>li>a {
        color: white;
        background-color: $background-nav-sidebar;
        padding: 5px 28px;
        margin:0px -20px;
    }

    .navmenu-nav>li>a:hover,
    .navmenu-nav>li>a:focus {
        color: $color-nav-hover-sidebar !important;
        background-color: $background-nav-hover-sidebar;
    }
    .navmenu-nav>.active>a,
    .navmenu-nav>.active>a:hover,
    .navmenu-nav>.active>a:focus {
        color: $color-nav-hover-active-sidebar !important;
        background-color: $background-nav-hover-active-sidebar;
    }

}

/**fix slide effect*/
.navmenu-fixed-right {
   left: auto !important;
}
