$background-goal: $background-grey;

.ibox-container{
    
    padding-top: 54px !important;
    
    button.close,
    a.close {
        position:relative;
        top:0px;
        right:0px;
        z-index: 10;
        width:24px;
        height:24px;
        opacity: 1;
        text-shadow: 0 0px 0 #FFF;
        text-align: center;
        font-weight: normal;
        
        color: #FFF;
        background: #000;
        &:hover{
          color: #000;
          background: #FFF; 
        }
    }
    
    button.close {
        line-height: 0.5;
    }
    
    div.iboxQuestion {
        font-weight:bold;
        font-size:18px;
    }
    
    div.iboxAnswerHeader, label.iboxAnswerHeader {
        color: $basecolor4;
        font-size:18px;
    }
    
    label.iboxAnswerHeader {
       width:200px;
    }
    
    &.ibox-goals{
        
        li {
            background: $background-goal;
            border: 1px solid $background-goal;
            padding: 6px;
            font-weight: 700;
            min-height: 70px;
            margin-bottom: 1em;
            
            button.close,
            a.close{
               margin: -6px;
               color: #FFF;
               background: #bababa;
               &:hover{
                 color: #bababa;
                 background: #FFF; 
               }                
                
            }
            
        }
        li:hover {
            border-color: $basecolor3;
        }
        
    }
    
    &.ibox-favorites{
        
       color: white;
        
        .ibox-favorites-content{
            position: relative;
            height: 150px;
            width:100%;
        }
        
        .item-indicator{
            float: right;
            margin-bottom: 10px;
            margin-top: 20px;
        }

        .icon-play{
            background-size: 32px 32px;
        }        
    }
}


.ibox-menu-container{
    
    padding-top: 6px !important;
    text-align: center;
    line-height:28px;
    
    .navbar-colors.row{
        
        min-height:30px;
        
        a {
            color:white;
        }
        
        a.selected {
           /* font-weight: bold;*/
        }
        
        a:hover {
            
            .red{
                background:  $basecolor1-hover;
            }
            .yellow{
                background:  $basecolor2-hover;
            }
            .green{
                background:  $basecolor3-hover;
            }
            .blue{
                background:  $basecolor4-hover;
            }

        }
        
    }
    
    @media (max-width: 768px){
        font-size: 12px;
        /*font-size: 1.8vw;*/
        /*line-height: 3vh;*/
        white-space: nowrap;
    }
    
}